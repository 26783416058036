import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import AccessibilityIcon from "../reUse/AccessibilityIcon";
import HelpIcon from "../reUse/HelpIcon";
import { Tooltip } from "@carbon/react";
import { matchPath, useLocation } from "react-router-dom";
import { Button, Link, Modal } from "@carbon/react";
import { useNavigate } from "react-router-dom";
export default function PortalHeader({ getData }) {
  const [open, setopen] = useState(true);
  const name = localStorage.getItem("name");
  const [modal, setmodal] = useState(false);
  const [image, setImage] = useState({ preview: "/john_doe.png", raw: "" });
  const { pathname } = useLocation();
 

  const handleOpenMenu = () => {
    getData(open);
    setopen(!open);
  };
  const navigate = useNavigate();
  useEffect(() => {
    let img = localStorage.getItem("image");
    setImage({ preview: img });
  }, []);
  return (
    <>
 
      <div className="portalHeader">
        <div className="textHeader">
          {pathname === "/portal/index" ? (
            <Typography variant="large" color="neutral.b800">
              Dashboard
            </Typography>
          ) : (
            ""
          )}
          {/* {pathname==="/portal/users"?        <Typography variant="large" color="neutral.b800">
          Users
        </Typography>:""} */}
          {pathname === "/portal/consultation" ? (
            <Typography variant="large" color="neutral.b800">
              Consultation
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/plans" ? (
            <Typography variant="large" color="neutral.b800">
            Plans & Billing
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/userInfo" ? (
            <Typography variant="large" color="neutral.b800">
              User Information
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/feedback" ? (
            <Typography variant="large" color="neutral.b800">
              Feedback
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/users" ? (
            <Typography variant="large" color="neutral.b800">
              Users
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/assessmentreports" ? (
            <Typography variant="large" color="neutral.b800">
              Reports
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/compare/" ? (
            <Typography variant="large" color="neutral.b800">
              Compare Reports
            </Typography>
          ) : (
            ""
          )}
          {pathname === "/portal/chatbot/" ? (
            <Typography variant="large" color="neutral.b800">
            Pan AI
            </Typography>
          ) : (
            ""
          )}
        </div>

        {pathname.includes("/portal/index") ? (
          ""
        ) : (
          <div className="icn usa" style={{ cursor: "pointer" }}>
            <div
              className="assesmentUser"
              onClick={() => navigate("/portal/index")}
            >
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#0065FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12L11 18"
                    stroke="#0065FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12L11 6"
                    stroke="#0065FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div style={{ paddingTop: "2px", paddingBottom: "4px" }}>
                <Typography variant="small" color="neutral.b800">
                  Back to Dashboard
                </Typography>
              </div>
            </div>
          </div>
        )}
        <div className="bars" onClick={handleOpenMenu}>
          <img src="/bars.svg" />
        </div>
      </div>
    </>
  );
}
