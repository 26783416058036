import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  LinearProgress,
  Grid,
  Typography,
  Button,
  Modal,
  TextInput,
} from "@mui/material";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@carbon/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import apiL from "../api/apiList";
import TeamChart from "./Dashboard/compareBlocks/TeamChart";
import LinearChart from "./Dashboard/compareBlocks/LinearChart";
import TableCompare from "./Dashboard/compareBlocks/TableCompare";
import HBar from "./Dashboard/compareBlocks/HorizontalChart";

export default function CompareMaintest(props) {
  const [searchParams] = useSearchParams();
  const aid = searchParams.get("id") || null;
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dtype, setDtype] = useState("");

  function sortDataArray(data) {
    const dasList = [
      "BUSINESS",
      "PURPOSE",
      "PEOPLE",
      "CUSTOMER",
      "MARKETING",
      "OPERATIONAL & TECHNOLOGY",
      "REPORTING & FEEDBACK",
      "FINANCIAL CONTROL & PLANNING",
      "CASH FLOW",
    ];
    return data.sort((a, b) => {
      const aIndex = dasList.indexOf(Object.keys(a)[0]);
      const bIndex = dasList.indexOf(Object.keys(b)[0]);
      return aIndex - bIndex;
    });
  }

  async function getData() {
    try {
      const response = await fetch(
        `https://server.panoramamas.com/admin/compare/session/${aid}`
      );
      const result = await response.json();
      const dasList = [
        "BUSINESS",
        "PURPOSE",
        "PEOPLE",
        "CUSTOMER",
        "MARKETING",
        "OPERATIONAL & TECHNOLOGY",
        "REPORTING & FEEDBACK",
        "FINANCIAL CONTROL & PLANNING",
        "CASH FLOW",
      ];
      const r = [];
      for (const e of dasList) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ data: result.data, dashboard: e });
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };
        const res = await fetch(
          `https://server.panoramamas.com/admin/compare/${
            result.type === "Team" ? "time" : "users"
          }/?profile=${result.profile}&server=${process.env.REACT_APP_SERVER}&DB=${process.env.REACT_APP_DB_NAME}`,
          requestOptions
        );
        const resData = await res.json();
        r.push(resData);
      }
      if (result.type === "Team") {
        setAllData(r);
      } else {
        const sortedData = sortDataArray(r);
        setAllData(sortedData);
      }
      setDtype(result.type);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <div>Loading</div>
      ) : dtype === "Team" ? (
        <div>
          <div className="ptlll">
            <div className="compareLogo">
              <div className="lgo">
                <img
                  src="/lgborder.svg"
                  alt=""
                  srcSet=""
                  style={{ height: "60px" }}
                />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <h5>Report Type : Time Based Comparison Report</h5>
              <h4 style={{padding:"40px",textAlign:'left'}}>
              Monitor your team's progress over time with PAT's time-based comparison feature. Employees can take assessments monthly, and their reports are compiled and visualized in detailed  graphs. This allows you to monitor changes and trends in your team's performance and understanding, offering valuable insights to guide strategic decisions and drive continuous improvement.
              </h4>
            </div>
            <div className="report">
              {allData.length !== 0 &&
                allData.map((e, i) => (
                  <div key={i}>
                    {e[0]?.Blocks.map((x, s) => (
                      <LinearChart
                        da={e}
                        das={x.Name}
                        i={s}
                        sessionid={aid}
                        username={e[0]?.name}
                        key={`${e[0]?.name}-${aid}-${x.Name}`}
                      />
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="ptlll">
            <div className="compareLogo">
              <div className="lgo">
                <img
                  src="/lgborder.svg"
                  alt=""
                  srcSet=""
                  style={{ height: "60px" }}
                />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <h5>Report Type : User Based Comparison Report</h5>
              <br/>     
              <h4 style={{padding:"40px",textAlign:'left'}}>
              Using our Panorama Assessment Tool (PAT), you can easily add employees and invite them to take assessments. After completion, you can compare individual reports, identifying variations in each employee's perception and understanding of the company's purpose and operations. Our platform visualizes these comparisons in clear, intuitive graphs, allowing you to identify areas of misalignment and promote better team alignment and collaboration.
              </h4>
            </div>
            <div className="report">
              {allData.length !== 0 &&
                allData.map((values, ii) =>
                  Object.entries(values).map(([key, value]) =>
                    key === "sessionid" ? null : (
                      <HBar data={value} name={key} key={key + ii} />
                    )
                  )
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
