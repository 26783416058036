import React, { useEffect, useState, useCallback, useRef } from "react";
import Layout from "../../Components/Portal/layout";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import apiL from "../../api/apiList";
import animationData from "../../panLoad.json";
import { Button, ButtonSkeleton } from "@carbon/react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  TextArea,
  SelectItem,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";

export default function ViewChat({ socket }) {
  const navigate = useNavigate();
  const [Count, setCount] = useState(-1);
  const { id } = useParams();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const [viewMode, setviewMode] = useState(false);
  const [compType, setcompType] = useState("null");
  const [allData, setallData] = useState([]);
  const [das, setdas] = useState("BUSINESS");
  const [Messages, setMessages] = useState([]);
  const [message, setmessage] = useState("");
  const [profileCode, setProfileCode] = useState("");
  const [vdata, setvdata] = useState({});
  const [loading, setloading] = useState(true);
  const lastMessageRef = useRef(null);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  function generateKey() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }
  function formatTextToHTML(text) {
    // Replace **text** with <strong>text</strong> for section headers
    text = text.replace(/\*\*(.*?)\*\*/gs, "<strong>$1</strong>");

    // Convert text to HTML paragraphs and list items
    let formattedText = "";
    let lines = text.split("\n"); // Split the text into lines

    // Process each line
    for (let line of lines) {
      if (line.trim().length === 0) continue; // Skip empty lines

      if (line.trim().startsWith("*")) {
        // Handle lines that start with '*', treating them as list items
        formattedText += `<li>${line.trim().substring(1).trim()}</li>`;
      } else {
        // Other lines are treated as paragraphs
        // Close any open list with </ul> before starting a new paragraph
        if (formattedText.endsWith("</li>")) {
          formattedText += "</ul>";
        }
        formattedText += `<p>${line.trim()}</p>`;
        // Start a new list if the next line is a bullet point
        if (
          lines.indexOf(line) < lines.length - 1 &&
          lines[lines.indexOf(line) + 1].trim().startsWith("*")
        ) {
          formattedText += "<ul>";
        }
      }
    }

    // Close any open list tags at the end of the text
    if (formattedText.endsWith("</li>")) {
      formattedText += "</ul>";
    }

    return formattedText;
  }
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      let chatData = Messages;
      chatData.push({
        role: "user",
        parts: [{ text: message }],
        name: "User",
        id: id,
        profile: profileCode,
        socketID: socket.id,
        date: getCurrentDate(),
      });
      setMessages(chatData);
      socket.emit("message", {
        role: "user",
        parts: [{ text: message }],
        name: "User",
        id: id,
        socketID: socket.id,
        profile: profileCode,
        date: getCurrentDate(),
      });
    }
    setmessage("");
  };
  async function getData() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `https://server.panoramamas.com/admin/compare/item/${id}/`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        let p = await result;
        setMessages(p.Chat);
        setProfileCode(p.ProfileCode);
        setvdata(p);
        setloading(false);
      })
      .catch((error) => console.error(error));
  }
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    socket.on("messageResponse", (data) => setMessages(data));
  }, [socket, Messages]);
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [Messages]);
  return (
    <>
      <Layout
        das={das}
        setdas={setdas}
        setallData={setallData}
        viewMode={viewMode}
        compType={compType}
      />

      <div className="ptl">
        <div className="innerPortal bg-grey-plan">
          <div className="report">
            <div className="chatviewMain">
              <div className="chatHeader">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ paddingRight: "16px" }}>
                      <img src="/lg1.svg" />
                    </div>
                    <div>
                      <h3>Conversation with Pan AI</h3>
                    </div>
                  </div>
                  <div
                    className="icn usa"
                    style={{ cursor: "pointer", paddingBottom: "16px" }}
                  >
                    <div
                      className="assesmentUser"
                      onClick={() => navigate("/portal/chatbot")}
                    >
                      <div>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 12H19"
                            stroke="#0065FF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 12L11 18"
                            stroke="#0065FF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 12L11 6"
                            stroke="#0065FF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div style={{ paddingTop: "2px", paddingBottom: "4px" }}>
                        <Typography variant="small" color="neutral.b800">
                          Back to Chats
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lnx"></div>
              </div>
              <div className="ChatBody">
                {loading ? (
                  <div className="ChatLoad">
                    <Lottie options={defaultOptions} height={320} width={300} />
                  </div>
                ) : (
                  <>
                    {Messages.map((e) => {
                      return e.name == "Panorama AI" ? (
                        <div className="panMessage" key={generateKey()}>
                          <Tag className="some-class" type="outline" size="sm">
                            {"Panorama AI"}
                          </Tag>
                          {/* <p>{e.parts[0].text}</p> */}
                          <div
                          className="rdxs"
                            dangerouslySetInnerHTML={{
                              __html: formatTextToHTML(e.parts[0].text),
                            }}
                          ></div>
                        </div>
                      ) : e.name == "Ignore" ? (
                        ""
                      ) : (
                        <div className="usMessage" key={generateKey()}>
                          <Tag className="some-class" type="outline" size="sm">
                            {"User"}
                          </Tag>
                          <p>{e.parts[0].text}</p>
                        </div>
                      );
                    })}
                  </>
                )}
                <div ref={lastMessageRef} />
              </div>
              <div className="chatinput">
                <div className="txtre">
                  <TextArea
                    labelText="Your Message"
                    placeholder="Type here"
                    rows={2}
                    id="text-area-1"
                    value={message}
                    onChange={(e) => setmessage(e.target.value)}
                  />
                </div>
                <div className="ghjk">
                  {Messages[Messages.length-1]?.name == "Panorama AI" ? (
                    <Button type="button" onClick={handleSendMessage}>
                      Send
                    </Button>
                  ) : (
                    <ButtonSkeleton > Working... </ButtonSkeleton >
                  )}

          
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
