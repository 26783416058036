import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "chart.js";
import "chartjs-adapter-date-fns";
Chart.register(...registerables);
export default function TeamChart({da}) {


  const options = {
    scales: {

      y: {
        max:120,
        beginAtZero: true,
        title: {
          display: true,
          text: "Value",
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Team Average",
      },
    },
  };
  return (
    <>
      <br></br>
      <br></br>
      <div className="bgf">
        <div className="barCOmpare">
        <div className="chartContainer">
          <Line data={da} options={options} />
        </div>
      </div>
      </div>
    </>
  );
}
