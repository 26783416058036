import Typography from "@mui/material/Typography";
import { Button, Link, Modal } from "@carbon/react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  TextArea,
  Select,
  SelectItem,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import Grid from "@mui/material/Grid";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import SinglePLan from "../../Components/Portal/plan/SinglePLan";
import React, { useContext, useEffect, useState, useRef } from "react";
import apiL from "../../api/apiList";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import SinglePlanDetails from "../../Components/Portal/plan/SinglePlanDetails";
import Layout from "../../Components/Portal/layout";
import LoadingScreen from "../../loading";
import { SendEmailFromServerEnterprise } from "../../Components/planFunctions";
export default function Plans({ checkPlan, checkValidity }) {
  const navigate = useNavigate();
  const [loadingBar, setloadingBar] = useState(true);
  const [setcderr, setsetcderr] = useState(false);
  const [IsChecked, setIsChecked] = useState(false);
  const [nplan, setnplan] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [Plan, setPlan] = useState([]);
  const [btndis, setbtndis] = useState(true);
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [pdet, setpdet] = useState({});
  const [name, setname] = useState("");
  const [desc, setdesc] = useState("");
  const [actPlan, setactPlan] = useState(null);
  const [modPlan, setmodPlan] = useState([]);
  const [terms, setterms] = useState(false);
  const [curency, setcurency] = useState("");
  const [isTrial, setisTrial] = useState(false);
  const [code, setcode] = useState("");
  const [codeERR, setcodeERR] = useState("");
  const [Invoice, setInvoice] = useState([]);
  const [modal, setmodal] = useState(false);
  const [modalE, setmodalE] = useState(false);
  const [modalP, setmodalP] = useState(false);
  const [payModal, setpayModal] = useState(false);
  const [logOutSes, setlogOutSes] = useState(false);
  const [trialPackage, settrialPackage] = useState({});
  let onb = localStorage.getItem("onboard");
  let filtArr = ["GBR", "GBT", "BDR", "BDT"];
  async function codePlan(code) {
    if (filtArr.includes(code)) {
      setcodeERR("Sorry, code doesn't exist");
    } else {
      let x = Plan.filter((e) => e.code == code);
      if (x.length !== 0) {
        planUpdate(x[0].id);
      } else {
        setcodeERR("Sorry, code doesn't exist");
      }
    }
  }
  async function init() {
    let x = await checkPlan();
    setactPlan(x);
  }
  function filterItems(data, param) {
    if (param === 55) {
      return data.filter((item) => item.code === "BDT");
    } else {
      return data.filter((item) => item.code === "GBT");
    }
  }
  function filterPlans(data, param) {
    if (param === 55) {
      return data.filter((item) => item.code === "BDR");
    } else {
      return data.filter((item) => item.code === "GBR");
    }
  }
  async function getProfile() {
    var myHeaders = new Headers();
    let id = localStorage.getItem("id");
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.getProfile + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        let s = dsa?.result?.business_info.revenue_currency;
        setcurency(dsa?.result?.business_info.revenue_currency);
        getPlan(dsa?.result?.business_info.revenue_currency);
        setisTrial(dsa?.result?.plan_info.trial);
      })
      .catch((error) => console.log("error", error));
  }
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false);
        navigate("/signin");
        const keysToRemove = [
          "vd",
          "id",
          "pid",
          "uid",
          "image",
          "onboard",
          "name",
          "invited",
        ];

        // Iterate over each key and remove the item from localStorage
        keysToRemove.forEach((key) => {
          localStorage.removeItem(key);
        });
      })
      .catch((error) => console.log("error", error));
  };
  if (onb === "false") {
    Logout();
    navigate("/signin");
  }
  const headers = ["Invoice Number", "Amount", "View"];
  async function sendMailCancel() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      subject: "Cancellation request from " + user.id,
      body: `<div>Dear Admin,<br/> ${user.id} , ${user.uid} has requested cancellation for his package. Please contact him . </div>`,
      recipient_emails: "tanvir@panoramamas.com",
    });

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.sendMail, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.status == "success") {
          setmodal(true);
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function planUpdates(n) {
    let uid = localStorage.getItem("uid");
    let profile = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      plan_id: n,
      user_id: uid,
      payment_gateway: curency == 55 ? "sslcommerz" : "stripe",
    });
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(apiL.ssl, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        let uri = dsa.result.redirection_url;
        window.location.replace(uri);
      })
      .catch((error) => console.log("error", error));
  }
  async function getPlan(cry) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getPlan + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.plan) {
          setPlan(dsa.result.plan);
          const filteredItems = filterItems(dsa.result.plan, cry);
          const filteredItemss = filterPlans(dsa.result.plan, cry);
          settrialPackage(filteredItems[0]);
          setmodPlan(filteredItemss);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getInvoice() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.invoiceList + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.invoices) {
          setInvoice(dsa.result.invoices);
          //No user handle
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  const scrollContainerRef = useRef(null);
  const handleButtonClick = () => {
    // Adjust the scroll position as needed, for example, move 100 pixels to the right
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 100;
    }
  };
  async function planUpdate(n) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      plan_id: n,
      transaction_id: 123456,
      amount: 5000,
      status: "validated",
    });
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.makeInvoice, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.invoices) {
          setUser({ ...user, pid: n });
          window.open(
            `${process.env.REACT_APP_BASE_URL}my/invoices/` +
              dsa.result.invoices
          );
          window.location.reload();
          getInvoice();
        }
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getProfile();
    init();
    getInvoice();
    setTimeout(() => {
      setloadingBar(false);
    }, 2000);
  }, []);
  return (
    <>
      <Layout logOutSes={logOutSes} setlogOutSes={setlogOutSes} />
      {loadingBar ? (
        <LoadingScreen />
      ) : (
        <>
          <Modal
            open={logOutSes}
            className="userAddModal"
            modalHeading="You will be logged out"
            primaryButtonText="Logout"
            secondaryButtonText="Cancel"
            onRequestClose={() => setlogOutSes(false)}
            onRequestSubmit={() => Logout()}
            size="sm"
          >
            <p style={{ marginBottom: "1rem" }}>
              Are you sure you want to log out of your Panorama account?
            </p>
          </Modal>
          <div className="innerPortal bg-grey-plan">
            {/* <Modal
        open={payModal}
        className='userAddModal'
        modalHeading="Request Sent"
        primaryButtonText="OK"
        onRequestClose={() => setpayModal(false)}
        onRequestSubmit={() => setpayModal(false)}
        size="xs"
      >
      
   
 
      </Modal> */}
            <Modal
              open={modal}
              className="userAddModal"
              modalHeading="Request Sent"
              primaryButtonText="OK"
              onRequestClose={() => setmodal(false)}
              onRequestSubmit={() => setmodal(false)}
              size="xs"
            >
              <p style={{ marginBottom: "1rem" }}>
                Cancellation request has been sent successfully. Someone from
                the Panorama team will contact with you regarding this within
                2-3 working days. Please have patience.
              </p>
            </Modal>
            <Modal
              open={modalE}
              className="userAddModal"
              modalHeading="Custom Plan Request"
              primaryButtonText="Send"
              secondaryButtonText="Cancel"
              primaryButtonDisabled={btndis}
              onRequestClose={() => {
                setemail("");
                setdesc("");
                setmodalE(false);
              }}
              onRequestSubmit={() => {
                SendEmailFromServerEnterprise(email, desc, name, phone);
                setemail("");
                setdesc("");
                setmodalE(false);
              }}
              size="xs"
            >
              <p style={{ marginBottom: "1rem" }}>
                Please fill out the following information:
                <div className="txtc">
                  <TextInput
                    size="lg"
                    className="txtF"
                    id="text-input-1"
                    type="email"
                    labelText="Email*"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                      if (
                        (email != "") &
                        (name != "") &
                        (phone != "") &
                        (desc != "")
                      ) {
                        setbtndis(false);
                      } else {
                        setbtndis(true);
                      }
                    }}
                  />
                </div>
                <div className="txtc">
                  <TextInput
                    size="lg"
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Name*"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                      if (
                        (email != "") &
                        (name != "") &
                        (phone != "") &
                        (desc != "")
                      ) {
                        setbtndis(false);
                      } else {
                        setbtndis(true);
                      }
                    }}
                  />
                </div>
                <div className="txtc">
                  <TextInput
                    size="lg"
                    className="txtF"
                    id="text-input-1"
                    type="tel"
                    labelText="Phone Number*"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => {
                      setphone(e.target.value);
                      if (
                        (email != "") &
                        (name != "") &
                        (phone != "") &
                        (desc != "")
                      ) {
                        setbtndis(false);
                      } else {
                        setbtndis(true);
                      }
                    }}
                  />
                </div>
                <div className="txtc">
                  <TextArea
                    className="txtF"
                    labelText="Briefly describe your requirements below"
                    rows={4}
                    value={desc}
                    onChange={(e) => {
                      setdesc(e.target.value);
                      if (
                        (email != "") &
                        (name != "") &
                        (phone != "") &
                        (desc != "")
                      ) {
                        setbtndis(false);
                      } else {
                        setbtndis(true);
                      }
                    }}
                  />
                </div>
              </p>
            </Modal>
            <Modal
              open={modalP}
              className="userAddModal"
              modalHeading="Confirm Purchase"
              primaryButtonText="OK"
              secondaryButtonText="Cancel"
              onRequestClose={() => {
                setmodalP(false);
              }}
              onRequestSubmit={() => {
                if (IsChecked) {
                  planUpdates(nplan);
                  setmodalP(false);
                } else {
                  setsetcderr(true);
                }
              }}
              size="xs"
            >
              <p style={{ marginBottom: "1rem" }}>
                You are about to buy{" "}
                <span style={{ fontWeight: "600", color: "#0065ff" }}>
                  {pdet.name}
                </span>{" "}
                plan
              </p>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={IsChecked}
                    onChange={(event) => {
                      setIsChecked(event.target.checked);
                      if (event.target.checked == true) {
                        setsetcderr(false);
                      }
                    }}
                  />
                  I have read and agreed to the{" "}
                  <a
                    target="_blank"
                    href="https://panoramamas.com/terms&conditions"
                  >
                    terms and conditions
                  </a>{" "}
                  ,{" "}
                  <a
                    target="_blank"
                    href="https://panoramamas.com/privacy_policy"
                  >
                    {" "}
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://panoramamas.com/refundPolicy"
                  >
                    refund policy
                  </a>
                </label>
                {setcderr ? (
                  <p style={{ color: "red", paddingTop: "16px" }}>
                    Please agree to continue
                  </p>
                ) : (
                  ""
                )}
              </div>
            </Modal>
            <div className="cardFullDashboard">
              <Grid container spacing={0}>
                <Grid sm={12} md={12} lg={6} xl={6}>
                  <div className="txtCardFullDash">
                    <div>
                      <Typography variant="h4B" color="neutral.b800">
                        Your Plan
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="small" color="neutral.b800">
                        {actPlan == null
                          ? "You haven’t selected a plan yet. Choose a plan to get started."
                          : " You’re all set! Proceed to the dashboard to start your assessment."}
                      </Typography>
                    </div>
                    <div></div>
                    {actPlan === null ? (
                      ""
                    ) : (
                      <Button
                        kind="primary"
                        size="lg"
                        onClick={() => navigate("/portal/index")}
                      >
                        Continue to Dashboard
                      </Button>
                    )}
                  </div>
                </Grid>
                <Grid sm={12} md={12} lg={6} xl={6}>
                  <div className="plan-txt2">
                    <div>
                      <Typography variant="large" color="neutral.b80">
                        Your Plan
                      </Typography>
                      <br />
                      <Typography variant="largeB" color="primary.b300">
                        {actPlan == null ? "No active plan" : actPlan.plan_name}
                      </Typography>
                    </div>
                    {/* <div className="plancta">
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div>
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div>
                <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div>
              </div> */}
                  </div>
                </Grid>
              </Grid>
            </div>
            <div
              className="cardQuarterDashboard"
              style={{ backgroundColor: "white", marginBottom: "20px" }}
            >
              <div className="planCard" style={{ opacity: "1" }}>
                <div className="headerPlan">
                  <Typography variant="largeB" color="neutral.b800">
                    Promo Plan
                  </Typography>
                </div>

                <div className="contentCard2">
                  <div className="package">
                    {/* <div className="packageName">
                      <h6>
                      Unlock premium features by redeeming your Custom Plan code
                      </h6>
                    </div> */}
                  </div>
                  <div style={{ paddingTop: "20px", width: "100%" }}>
                    <TextInput
                      className="txtF"
                      placeholder="Enter Code Here"
                      value={code}
                      onChange={(e) => {
                        setcode(e.target.value);
                        setcodeERR("");
                      }}
                    />
                  </div>
                </div>
                <div style={{ height: "30px" }}>
                  <p style={{ color: "red", paddingBottom: "4px" }}>
                    {codeERR}
                  </p>
                </div>
                <Button
                  onClick={() => {
                    codePlan(code);
                  }}
                  className="btnss"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                  kind="primary"
                  size="lg"
                >
                  Redeem Code
                </Button>
              </div>
            </div>
            {isTrial ? (
              ""
            ) : (
              <div
                className="cardQuarterDashboard"
                style={{ backgroundColor: "white" }}
              >
                <div className="planCard" style={{ opacity: "1" }}>
                  <div className="headerPlan">
                    <Typography variant="largeB" color="neutral.b800">
                      {trialPackage?.name}
                    </Typography>
                  </div>

                  <div className="contentCard2">
                    <div className="package">
                      <div className="packageName">
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: trialPackage?.description,
                          }}
                        ></h6>
                      </div>

                      <div style={{ display: "flex", justifyContent: "right" }}>
                        {trialPackage?.attributes?.map((item, index) => {
                          if (index % 3 === 0) {
                            return (
                              <ul className="packageInfo" key={index}>
                                <li>
                                  {trialPackage?.attributes[index].active ? (
                                    <img src="/tick.svg" alt="tick" />
                                  ) : (
                                    <img src="/crs.svg" alt="tick" />
                                  )}
                                  <div className="details">
                                    <span>
                                      {trialPackage?.attributes[index].remarks}
                                    </span>
                                    <span>
                                      {trialPackage?.attributes[index].name}
                                    </span>
                                  </div>
                                </li>
                                {trialPackage?.attributes[index + 1] && (
                                  <li>
                                    {trialPackage?.attributes[index + 1]
                                      .active ? (
                                      <img src="/tick.svg" alt="tick" />
                                    ) : (
                                      <img src="/crs.svg" alt="tick" />
                                    )}
                                    <div className="details">
                                      <span>
                                        {
                                          trialPackage?.attributes[index + 1]
                                            .remarks
                                        }
                                      </span>
                                      <span>
                                        {
                                          trialPackage?.attributes[index + 1]
                                            .name
                                        }
                                      </span>
                                    </div>
                                  </li>
                                )}
                                {trialPackage?.attributes[index + 2] && (
                                  <li>
                                    {trialPackage?.attributes[index + 2]
                                      .active ? (
                                      <img src="/tick.svg" alt="tick" />
                                    ) : (
                                      <img src="/crs.svg" alt="tick" />
                                    )}
                                    <div className="details">
                                      <span>
                                        {
                                          trialPackage?.attributes[index + 2]
                                            .remarks
                                        }
                                      </span>
                                      <span>
                                        {
                                          trialPackage?.attributes[index + 2]
                                            .name
                                        }
                                      </span>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </div>

                  <Button
                    onClick={() => {
                      planUpdate(trialPackage.id);
                      init();
                      getInvoice();
                    }}
                    className="btnss"
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      justifyContent: "center",
                    }}
                    kind="primary"
                    size="lg"
                  >
                    Start Now
                  </Button>
                </div>
              </div>
            )}

            <div className="featured-plan" ref={scrollContainerRef}>
              <div className="featured-top">
                <div className="featured-plans">
                  <div className="xczxzz" onClick={handleButtonClick}>
                    <div>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_78_2)">
                          <path
                            d="M6.25 15H23.75"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.75 20L23.75 15"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.75 10L23.75 15"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_78_2">
                            <rect width="30" height="30" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div className="featured-header">
                    {/* <div>
                      <Typography variant="small" color="neutral.b800">
                        Features
                      </Typography>
                    </div> */}
                    <div>
                      <Typography
                        variant="h4B"
                        color="neutral.b800"
                        className="heading"
                      >
                        Features by Plan
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="small" color="neutral.b800">
                        Explore our available plans and choose the one that
                        aligns with your business goals.
                      </Typography>
                    </div>
                  </div>
                  {modPlan?.map((e) => {
                    return (
                      <SinglePLan
                        fplan={e}
                        actPlan={actPlan?.plan_name}
                        setnplan={setnplan}
                        Planname={e.name}
                        setpdet={setpdet}
                        isCurrent={e.is_current_plan}
                        description={e.description}
                        key={e.name}
                        planID={e.id}
                        price={e.price}
                        currency={curency}
                        modalE={modalE}
                        setmodalE={setmodalE}
                        invoice={getInvoice}
                        email={email}
                        setemail={setemail}
                        desc={desc}
                        setdesc={setdesc}
                        modalP={modalP}
                        setmodalP={setmodalP}
                        planUpdate={planUpdate}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="featured-bottom">
                <div className="featured-plans">
                  <div style={{ minWidth: "303px", maxWidth: "303px" }}>
                    <ul className="features-list-item">
                      {Plan[0]?.attributes.map((e) => {
                        return <li key={e.name}>{e.name}</li>;
                      })}
                    </ul>
                  </div>
                  {modPlan?.map((e) => {
                    return <SinglePlanDetails e={e?.attributes} />;
                  })}
                </div>
              </div>
            </div>
            <div className="cancellation">
              <Grid container spacing={0}>
                <Grid sm={12} md={12} lg={12} xl={12}>
                  <div style={{ marginBottom: "12px" }}>
                    <Typography variant="h4B" color="neutral.b800">
                      Invoices
                    </Typography>
                  </div>
                  <div style={{ width: "100%" }}>
                    {" "}
                    <Table size="lg" useZebraStyles={false}>
                      <TableHead>
                        {" "}
                        <TableRow>
                          {" "}
                          {headers.map((header) => (
                            <TableHeader id={header.key} key={header}>
                              {header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Invoice.map((row) => (
                          <TableRow key={row.invoice_link}>
                            <TableCell>{row.invoice_number}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>
                              {" "}
                              <span
                                onClick={() => window.open(row.invoice_link)}
                                style={{ color: "#0065FF", cursor: "pointer" }}
                              >
                                View Invoice
                              </span>{" "}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="cancellation">
              <Grid container spacing={0}>
                <Grid sm={12} md={12} lg={6} xl={6}>
                  <div style={{ marginBottom: "12px" }}>
                    <Typography variant="h4B" color="neutral.b800">
                      Request Cancellation
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="small" color="neutral.b800">
                      You will be contacted by our support team to process the
                      cancellation of your assessment plan and assess if you are
                      eligible for a refund. Please refer to our{" "}
                      <a href="https://panoramamas.com/terms&conditions">
                        Terms & Conditions
                      </a>{" "}
                      and{" "}
                      <a href="https://panoramamas.com/refundPolicy">
                        Refund Policy
                      </a>{" "}
                      before you request a cancellation.
                    </Typography>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={terms} />}
                      onClick={() =>
                        terms == false ? setterms(true) : setterms(false)
                      }
                      label="I have read the Terms & Conditions and Refund Policy"
                    />
                  </FormGroup>
                  <Button
                    className="theme-button"
                    style={{ background: "#0065ff", marginTop: "20px" }}
                    disabled={terms == false ? true : false}
                    onClick={() => sendMailCancel()}
                  >
                    Request Cancellation
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
}
