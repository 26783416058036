import React, { useContext, useEffect, useState } from "react";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import apiL from "../api/apiList";
export default function CompareMaintestR() {
  const [EmailRec, setEmailRec] = useState("");
  const [emailTemp, setemailTemp] = useState("");
  const [Message, setMessage] = useState("");
  const [subject, setsubject] = useState("");
  async function test(){
	var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		let raw = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: process.env.REACT_APP_DB_NAME,
				login: "alif@panoramamas.com",
				password: "panorama123",
			},
	  })
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
			redirect: "follow",
		};
		fetch(apiL.signin, requestOptions)
			.then(async (response) => {
			    var raws = JSON.stringify({
					subject: subject,
					body: emailTemp,
					recipient_emails: EmailRec,
				  });
				  var requestOptionss = {
					method: "POST",
					credentials: "include",
					headers: {
					  "Content-Type": "application/json",
					},
					body: raws,
					redirect: "follow",
				  };
						fetch(apiL.sendMail, requestOptionss)
						  .then(async (response) => {
							let dsa = await response.json();
							setMessage(dsa.result.message)
							if (dsa.error && dsa.error.message == "Odoo Session Expired") {
							}
						  })
						  .catch((error) => console.log("error", error));
			})
			.catch((error) => console.log("error", error));
  }
  return (
    <div style={{ margin: "40px",textAlign:'center' }}>
      <TextInput
        className="txtF"
        id="text-input-1"
        type="text"
        size="lg"
        labelText="Email"
        placeholder="Emails seperatted by comma"
		value={EmailRec}
		onChange={(e)=>setEmailRec(e.target.value)}
      />
      <br />
      <br />
      <TextInput
        className="txtF"
        id="text-input-1"
        type="text"
        size="lg"
        labelText="Subject"
        placeholder="Email Subject"
		value={subject}
		onChange={(e)=>setsubject(e.target.value)}
      />
	        <br />
      <br />
      <TextInput
        className="txtF"
        id="text-input-1"
        type="text"
        size="lg"
        labelText="Email Template"
        placeholder="Emails Template Code"
		value={emailTemp}
		onChange={(e)=>setemailTemp(e.target.value)}
      />
    <br />
      <br />
      <Button onClick={()=>test()}>Check Email Template</Button>
	  <br />
      <br />
	  <p style={{color:'red'}}>{Message}</p>
    </div>
  );
}
