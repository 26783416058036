import React, { useEffect, useState } from "react";
import PortalHeader from "../../Components/Portal/header";
import Layout from "../../Components/Portal/layout";
import UserInfo from "../../Components/Portal/UserInfo";
import apiL from "../../api/apiList";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  Select,
  SelectItem,
  TextInput,
} from "@carbon/react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../loading";
export default function User_info({ notify }) {
  const [data, setdata] = useState({});
  const [loadingBar, setloadingBar] = useState(true)
  const navigate = useNavigate();
  const [logOutSes, setlogOutSes] = useState(false);
  let onb = localStorage.getItem("onboard");
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false);
        navigate("/signin");
        const keysToRemove = ['vd', 'id', 'pid', 'uid', 'image', 'onboard', 'name', 'invited'];

// Iterate over each key and remove the item from localStorage
keysToRemove.forEach(key => {
  localStorage.removeItem(key);
});
      })
      .catch((error) => console.log("error", error));
  };
  if (onb === "false") {
    Logout();
    navigate("/signin");
  }
  async function getProfile() {
    let id = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.getProfile + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.personal_info.user_code == id) {
          setdata(dsa.result);
        }
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getProfile();
    setTimeout(()=>{
 
      setloadingBar(false)
    }, 2000)
  }, []);

  return (
    <div className="ptl">
      <Modal
        open={logOutSes}
        className="userAddModal"
        modalHeading="You will be logged out"
        primaryButtonText="Logout"
        secondaryButtonText="Cancel"
        onRequestClose={() => setlogOutSes(false)}
        onRequestSubmit={() => Logout()}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Are you sure you want to log out of your Panorama account?
        </p>
      </Modal>
      <Layout logOutSes={logOutSes} setlogOutSes={setlogOutSes} />

      {/* <PortalHeader /> */}
      {loadingBar? <LoadingScreen/>:  <>
      <UserInfo data={data} notify={notify} />
      </>}
    </div>
  );
}
