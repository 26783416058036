import Typography from "@mui/material/Typography";
import { Button } from "@carbon/react";
import Lottie from "react-lottie";
import animationData from "../../../lottieAnimate.json";
import PlanIcon from "../../reUse/PlanIcon";
import UserCardIcon from "../../reUse/UserCardIcon";
import ConsultCardIcon from "../../reUse/ConsultCardIcon";
import PlanIconLarge from "../../reUse/PlanIconLarge";
import SafetyLargeIcon from "../../reUse/SafetyLargeIcon";
import React, { useContext, useEffect, useState } from "react";
import apiL from "../../../api/apiList";
import { UserContext } from "../../../userContext";
import { useNavigate } from "react-router-dom";
import AssesmentCard from "./AssesmentCard";
export default function PlanCard({ data, planData }) {
  const navigate = useNavigate();
  const invited = localStorage.getItem("invited");

  return (
    <div className="cardQuarterDashboard">
      <div className="planCard" style={{ opacity: "1" }}>
        <div className="headerPlan">
          <PlanIcon />
          <Typography variant="small" color="neutral.b800">
            Status
          </Typography>
        </div>
        {data === null ? (
          <div className="contentCard">
            <SafetyLargeIcon />
            <div>
              <Typography variant="small" color="neutral.b200">
                Please select a plan to take the assessment
              </Typography>
            </div>
          </div>
        ) : (
          <div className="contentCard2">
            <p>Summary of your current plan.</p>
            <div className="package">
              <div className="packageName">
                <span>Your Plan</span>
                <h5>{data?.name}</h5>
              </div>
            </div>
            <div className="packages">
            {data.attributes.map((item, index) => {
  if (index % 4 === 0) { // Change 3 to 4
    return (
      <ul className="packageInfo" key={index}>
        <li>
          {data.attributes[index].active ? (
            <img src="/tick.svg" alt="tick" />
          ) : (
            <img src="/crs.svg" alt="tick" />
          )}
          <div className="details">
            <span>{data.attributes[index].remarks}</span>
            <span style={{color:'#0065ff',fontWeight:'500'}}>
              {data.attributes[index].name}
            </span>
          </div>
        </li>
        {data.attributes[index + 1] && (
          <li>
            {data.attributes[index + 1].active ? (
              <img src="/tick.svg" alt="tick" />
            ) : (
              <img src="/crs.svg" alt="tick" />
            )}
            <div className="details">
              <span>{data.attributes[index + 1].remarks}</span>
              <span style={{color:'#0065ff',fontWeight:'500'}}>
                {data.attributes[index + 1].name}
              </span>
            </div>
          </li>
        )}
        {data.attributes[index + 2] && (
          <li>
            {data.attributes[index + 2].active ? (
              <img src="/tick.svg" alt="tick" />
            ) : (
              <img src="/crs.svg" alt="tick" />
            )}
            <div className="details">
              <span>{data.attributes[index + 2].remarks}</span>
              <span style={{color:'#0065ff',fontWeight:'500'}}>
                {data.attributes[index + 2].name}
              </span>
            </div>
          </li>
        )}
        {data.attributes[index + 3] && ( // Add a condition to render the 4th item
          <li>
            {data.attributes[index + 3].active ? (
              <img src="/tick.svg" alt="tick" />
            ) : (
              <img src="/crs.svg" alt="tick" />
            )}
            <div className="details">
              <span>{data.attributes[index + 3].remarks}</span>
              <span style={{color:'#0065ff',fontWeight:'500'}}>
                {data.attributes[index + 3].name}
              </span>
            </div>
          </li>
        )}
      </ul>
    );
  }
  return null;
})}
            </div>
          </div>
        )}
        {invited === "false" ? (
          <Button
            onClick={() => navigate("/portal/plans")}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
          >
            View Available Plans
          </Button>
        ) : (
          <Button
            onClick={() => navigate("/portal/plans")}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
          >
            View Available Plans
          </Button>
        )}
      </div>
    </div>
  );
}
