import * as yup from "yup";

const userSchema = yup.object().shape({
    first_name: yup.string().required("This is a required field"),
    last_name: yup.string().required("This is a required field"),
    title: yup.string().required("This is a required field"),
    responsibilities: yup.string("This is a required field"),
    email: yup.string().email("Email must be a valid email").required("This is a required field"),
    phone: yup.string().required("This is a required field"),
    where_from_about_us: yup.string().required("This is a required field"),
    referral_code: yup.string(),
    promo_code: yup.string(),
    responsibilities:yup.string().required("This is a required field"),
  });
  const businessSchema = yup.object().shape({
    business_name: yup.string().required("This is a required field"),
    industry_id: yup.string().required("This is a required field"),
    business_type: yup.string().required("This is a required field"),
    business_state: yup.string().required("This is a required field"),
    website: yup.string(),
    address_line: yup.string().required("This is a required field"),
    address_line_two: yup.string(),
    state_id: yup.string().required("This is a required field"),
    city:yup.string().required("This is a required field"),
    zip: yup.string().required("This is a required field"),
    business_age: yup.number("This must be a number").required("This is a required field").typeError('A number is required'),
    legal_structure: yup.string().required("This is a required field"),
    // revenue_currency: yup.string().required("This is a required field"),
    revenue_range: yup.string().required("This is a required field"),
    employee_number: yup.number("This must be a number").required("This is a required field").typeError('A number is required'),
    physical_location: yup.number("This must be a number").required("This is a required field").typeError('A number is required'),
    country_id: yup.string().required("This is a required field"),
});
const business = yup.object().shape({
  business_name: yup.string().required("This is a required field"),
  industry_id: yup.string().required("This is a required field"),
  business_type: yup.string().required("This is a required field"),
  business_state: yup.string().required("This is a required field"),
  website: yup.string(),
  // address_line: yup.string().required("This is a required field"),
  // address_line_two: yup.string(),
  // state_id: yup.string().required("This is a required field"),
  // city:yup.string().required("This is a required field"),
  // zip: yup.string().required("This is a required field"),
  business_age: yup.number("This must be a number").required("This is a required field").typeError('A number is required'),
  legal_structure: yup.string().required("This is a required field"),
  // revenue_currency: yup.string().required("This is a required field"),
  revenue_range: yup.string().required("This is a required field"),
  employee_number: yup.number("This must be a number").required("This is a required field").typeError('A number is required'),
  physical_location: yup.number("This must be a number").required("This is a required field").typeError('A number is required'),
  // country_id: yup.string().required("This is a required field"),
});
  export {userSchema,businessSchema,business}