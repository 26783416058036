import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
  MultiSelect,
  Tag,
} from "@carbon/react";
import {
  userSchema,
  businessSchema,
} from "../../Components/onBoard/profileSchema";
import apiL from "../../api/apiList";
export default function UserProfile({
  setcount,
  setProfile,
  setprofileID,
  profileData,
}) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });
  const onSubmit = async (data) => {
    setProfile({ ...data });
    // setValue({...data,responsibilities:respstring})
    setcount(1);
  };
  async function getProfile() {
    let id = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.getProfile + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result.personal_info.user_code == id) {
          navigate("/onboard/?phase=2");
        }
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    let defaultValues = { ...profileData };
    reset({ ...defaultValues });
  }, []);

  return (
    <Container maxWidth="xl">
      <form className="responsive-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="onbUserProfileBtn">
              <div>
                <Typography variant="large" color="neutral.b800">
                  Let’s get started...
                </Typography>
              </div>
              <div>
                <Typography variant="h4B" color="neutral.b800">
                  Please tell us a bit about yourself
                </Typography>
              </div>

              <div className="laptopphoto">
                <img src="/businessProfile.png" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="onBScroll">
              <div className="onbInput">
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="first_name"
                    type="text"
                    labelText={
                      <span>
                        First Name<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="First Name"
                    invalid={errors.first_name ? true : false}
                    invalidText={errors.first_name?.message}
                    {...register("first_name", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Last Name<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Last Name"
                    invalid={errors.last_name ? true : false}
                    invalidText={errors.last_name?.message}
                    {...register("last_name", { required: true })}
                  />
                </div>
                {/* <div className="onin">
                <TextInput
                  className="txtF"
                  id="text-input-1"
                  type="text"
                  labelText="Business Name"
                  placeholder="Business Name"
                  invalid={errors.business_name ? true : false}
                  invalidText={errors.business_name?.message}
                  {...register("business_name", { required: true })}
                />
              </div> */}
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Job Title<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="e.g. CTO, VP, Director"
                    invalid={errors.title ? true : false}
                    invalidText={errors.title?.message}
                    {...register("title", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Responsibilities<span style={{ color: "red" }}>*</span>{" "}
                        (Task Related)
                      </span>
                    }
                    placeholder="e.g.  Bookkeeping, Marketing, Sales"
                    invalid={errors.responsibilities ? true : false}
                    invalidText={errors.responsibilities?.message}
                    {...register("responsibilities", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Email<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Email"
                    invalid={errors.email ? true : false}
                    invalidText={errors.email?.message}
                    {...register("email", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="tel"
                    labelText={
                      <span>
                        Phone<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Phone"
                    invalid={errors.phone ? true : false}
                    invalidText={errors.phone?.message}
                    {...register("phone", { required: true })}
                  />
                </div>

                <div className="onin">
                  <Select
                    id="select-1"
                    defaultValue=""
                    labelText={
                      <span>
                        Where did you hear about us?
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    invalid={errors.where_from_about_us ? true : false}
                    invalidText={errors.where_from_about_us?.message}
                    {...register("where_from_about_us", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value=""
                      text="Choose an option"
                      selected
                    />
                    <SelectItem value="search_engine" text="Search Engine" />
                    <SelectItem value="instagram" text="Instagram" />
                    <SelectItem value="twitter" text="Twitter" />
                    <SelectItem value="linkedin" text="Linkedin" />
                    <SelectItem value="facebook" text="Facebook" />
                    <SelectItem
                      value="other_social"
                      text="Other social media"
                    />
                    <SelectItem value="email" text="Email" />
                    <SelectItem value="blog" text="Blog" />
                    <SelectItem value="podcast" text="Word of mouth" />
                    <SelectItem value="mouth" text="Podcast" />
                    <SelectItem
                      value="conference"
                      text="Conference or Tradeshow"
                    />
                    <SelectItem value="referral" text="Referral" />
                    <SelectItem value="radio" text="Radio" />
                    <SelectItem value="tv" text="TV" />
                    <SelectItem value="other" text="Other" />
                  </Select>
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Referral Code(if any)"
                    placeholder="Referral Code(if any)"
                    invalid={errors.referral_code ? true : false}
                    invalidText={errors.referral_code?.message}
                    {...register("referral_code")}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Promo code(if any)"
                    placeholder="Promo code(if any)"
                    invalid={errors.promo_code ? true : false}
                    invalidText={errors.promo_code?.message}
                    {...register("promo_code")}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="onbBtn">
                {/* <Button kind="primary" size="lg" className="btnss" type="submit">
                Next
              </Button> */}
                <Button kind="primary" size="lg" type="submit">
                  Next
                </Button>
              </div>
              <div style={{ paddingTop: "28px", paddingLeft: "8px" }}>
                <p
                  style={{
                    color: "#2461FF",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("https://forms.gle/HDzxxsNVhr7KrJZN8")
                  }
                >
                  Having a problem? Report an issue
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
