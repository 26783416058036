import React, { useState, useEffect } from "react";
import Layout from "./layout";
import JotformEmbed from "react-jotform-embed";
import Grid from "@mui/material/Grid";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  Select,
  SelectItem,
  TextInput,
} from "@carbon/react";
import apiL from "../../api/apiList";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../loading";
export default function FeedbackPage() {
  const [loadingBar, setloadingBar] = useState(true)
  const navigate = useNavigate();
  const [logOutSes, setlogOutSes] = useState(false);
  let onb = localStorage.getItem("onboard");
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false)
        navigate("/signin");
        const keysToRemove = ['vd', 'id', 'pid', 'uid', 'image', 'onboard', 'name', 'invited'];

// Iterate over each key and remove the item from localStorage
keysToRemove.forEach(key => {
  localStorage.removeItem(key);
});
      })
      .catch((error) => console.log("error", error));
  };
  if (onb === "false") {
    Logout();
    navigate("/signin");
  }
  useEffect(() => {
    setTimeout(()=>{
 
      setloadingBar(false)
    }, 2000)
  }, [])
  
  return (
    <div>
      <Layout logOutSes={logOutSes} setlogOutSes={setlogOutSes} />
      {loadingBar? <LoadingScreen/>:  <>
      <Modal
        open={logOutSes}
        className="userAddModal"
        modalHeading="You will be logged out"
        primaryButtonText="Logout"
        secondaryButtonText="Cancel"
        onRequestClose={() => setlogOutSes(false)}
        onRequestSubmit={() => Logout()}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Are you sure you want to log out of your Panorama account?
        </p>
      </Modal>
      <div className="innerPortal bg-grey-plan">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfCn-UyCXtwLjR9n6d1bAWHbVQ_GRtO0CXw5QLVXXHCb_bWXw/viewform?embedded=true"
              width="640"
              height="1300"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
        </Grid>
      </div>
      </>}
    </div>
  );
}
