

const apiL={
    signup:`${process.env.REACT_APP_BASE_URL}signup`,
    otp:`${process.env.REACT_APP_BASE_URL}api/otp_mail_send`,
    reset:`${process.env.REACT_APP_BASE_URL}forget_password`,
    getCurrency: `${process.env.REACT_APP_BASE_URL}get_currencies`,
    signin: `${process.env.REACT_APP_BASE_URL}web/session/authenticate`,
    getProfile: `${process.env.REACT_APP_BASE_URL}get_profile/`,
    updateProfile: `${process.env.REACT_APP_BASE_URL}update_profile/`,
    createProfile: `${process.env.REACT_APP_BASE_URL}create_profile/`,
    onBoarding:  `${process.env.REACT_APP_BASE_URL}onboarding_question/`,
    getUser:`${process.env.REACT_APP_BASE_URL}get_user_status/`,
    getCountry:`${process.env.REACT_APP_BASE_URL}get_country_list/`,
    getIndustry:`${process.env.REACT_APP_BASE_URL}get_industry_list/`,
    getConsultant:`${process.env.REACT_APP_BASE_URL}get_consultants/`,
    logout:`${process.env.REACT_APP_BASE_URL}web/session/destroy`,
    userList:`${process.env.REACT_APP_BASE_URL}get_user_list/`,
    remainUser:`${process.env.REACT_APP_BASE_URL}remaining_user/`,
    addUser:`${process.env.REACT_APP_BASE_URL}add_user/`,
    sendMail:`${process.env.REACT_APP_BASE_URL}api/send_email`,
    getPlan:`${process.env.REACT_APP_BASE_URL}get_plans/`,
    invoiceList:`${process.env.REACT_APP_BASE_URL}get_invoice/`,
    makeInvoice:`${process.env.REACT_APP_BASE_URL}make_invoice`,
    getAssesment : `${process.env.REACT_APP_BASE_URL}get_assessment/`,
    asseslist:`${process.env.REACT_APP_BASE_URL}get_assessment_list/`,
    updatePicture:`${process.env.REACT_APP_BASE_URL}update_picture/`,
    createAssesment:`${process.env.REACT_APP_BASE_URL}create_assessment/`,
    getDashboardBusiness:`${process.env.REACT_APP_BASE_URL}get_dashboard/`,
    changePassword:`${process.env.REACT_APP_BASE_URL}change_password`,
    ssl:`${process.env.REACT_APP_BASE_URL}payment_initiate/`
}
export default apiL