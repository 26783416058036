import React, { createContext, useContext, useEffect, useState } from 'react';
import socketIO from 'socket.io-client';
import { useLocation } from 'react-router-dom';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const pathsWithSocket = [
      "^/portal/chatbot/?$",
      "^/portal/chatbot/\\d+$",
    ];

    const shouldConnectSocket = pathsWithSocket.some((path) =>
      new RegExp(path).test(location.pathname)
    );

    if (shouldConnectSocket && !socket) {
   
      const newSocket = socketIO.connect("https://localhost:4000");
      setSocket(newSocket);
    } else if (!shouldConnectSocket && socket) {
 
      socket.disconnect();
      setSocket(null);
    }

    return () => {
      if (socket) {
   
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [location.pathname, socket]);


  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
