import React from "react";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";

export default function ReportNav({
  assesID,
  modal,
  getParamActiveValue,
  attr,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pid = localStorage.getItem("pid");
  const invited = localStorage.getItem("invited");
  let [searchParams] = useSearchParams();
  let id = searchParams.get("code");
  return (
    <>
      {attr.length == 0 ? (
        ""
      ) : (
        <div className="serve">
          <div
            className={
              pathname.includes("/portal/report/overview/")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
              navigate("/portal/report/overview/?id=" + assesID + "&code=" + id)
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Overview
              </Typography>
            </div>
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                fill="black"
              />
            </svg>
          </div>
          <div
            className={
              pathname.includes("/portal/report/purpose")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
              navigate("/portal/report/purpose/?id=" + assesID + "&code=" + id)
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Purpose
              </Typography>
            </div>
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                fill="black"
              />
            </svg>
          </div>
          <div
            className={
              pathname.includes("/portal/report/people")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
              navigate("/portal/report/people/?id=" + assesID + "&code=" + id)
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                People
              </Typography>
            </div>
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                fill="black"
              />
            </svg>
          </div>
  
          <div
            className={
              getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? "ser notAllow"
                : pathname.includes("/portal/report/customer")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? modal(true)
                : navigate(
                    "/portal/report/customer/?id=" + assesID + "&code=" + id
                  )
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Customer
              </Typography>
            </div>
            {getParamActiveValue(attr, "Detailed Reports")=="No Access Granted" ? (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
          <div
            className={
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? "ser notAllow"
                : pathname.includes("/portal/report/marketing")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? modal(true)
                : navigate(
                    "/portal/report/marketing/?id=" + assesID + "&code=" + id
                  )
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Marketing
              </Typography>
            </div>
            {getParamActiveValue(attr, "Detailed Reports")=="No Access Granted" ? (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
          <div
            className={
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? "ser notAllow"
                : pathname.includes("/portal/report/operation")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? modal(true)
                : navigate(
                    "/portal/report/operation/?id=" + assesID + "&code=" + id
                  )
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Operation
              </Typography>
            </div>
            {getParamActiveValue(attr, "Detailed Reports")=="No Access Granted" ? (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                  fill="black"
                />
              </svg>
            )}
          </div>

          <div
            className={
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? "ser notAllow"
                : pathname.includes("/portal/report/reports")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? modal(true)
                : navigate(
                    "/portal/report/reports/?id=" + assesID + "&code=" + id
                  )
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Reports
              </Typography>
            </div>
            {getParamActiveValue(attr, "Detailed Reports")=="No Access Granted" ? (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                  fill="black"
                />
              </svg>
            )}
          </div>

          <div
            className={
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? "ser notAllow"
                : pathname.includes("/portal/report/financials")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
              
              getParamActiveValue(attr, "Detailed Reports")=="No Access Granted" ||
              getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? modal(true)
                : navigate(
                    "/portal/report/financials/?id=" + assesID + "&code=" + id
                  )
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Financials
              </Typography>
            </div>
            {getParamActiveValue(attr, "Detailed Reports")=="No Access Granted" ? (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
          <div
            className={
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? "ser notAllow"
                : pathname.includes("/portal/report/cashflow")
                ? "ser serActive"
                : "ser"
            }
            onClick={() =>
               getParamActiveValue(attr, "Detailed Reports")=="No Access Granted"
                ? modal(true)
                : navigate(
                    "/portal/report/cashflow/?id=" + assesID + "&code=" + id
                  )
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                CashFlow
              </Typography>
            </div>
            {getParamActiveValue(attr, "Detailed Reports")=="No Access Granted" ? (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8947 7.73478 16 8 16C8.26522 16 8.51957 15.8947 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11ZM13 7.00001H5V5.00001C4.99854 4.4062 5.17334 3.82531 5.50226 3.33092C5.83118 2.83652 6.29942 2.45086 6.84768 2.22277C7.39594 1.99469 7.99956 1.93444 8.58209 2.04966C9.16462 2.16488 9.69985 2.45038 10.12 2.87001C10.4959 3.25408 10.7649 3.72984 10.9 4.25001C10.9328 4.37739 10.9904 4.49706 11.0695 4.60218C11.1486 4.70731 11.2476 4.79582 11.3609 4.86268C11.4742 4.92954 11.5995 4.97343 11.7298 4.99185C11.86 5.01026 11.9926 5.00284 12.12 4.97001C12.2474 4.93718 12.3671 4.87958 12.4722 4.8005C12.5773 4.72143 12.6658 4.62242 12.7327 4.50913C12.7995 4.39584 12.8434 4.27049 12.8618 4.14024C12.8802 4.00999 12.8728 3.87739 12.84 3.75001C12.6122 2.88481 12.1603 2.09495 11.53 1.46001C10.8302 0.762377 9.93934 0.287747 8.96996 0.0960545C8.00058 -0.0956385 6.99614 0.00420153 6.08346 0.382967C5.17078 0.761733 4.3908 1.40244 3.84201 2.22418C3.29321 3.04593 3.00021 4.01186 3 5.00001V7.00001C2.20435 7.00001 1.44129 7.31608 0.87868 7.87869C0.316071 8.4413 0 9.20436 0 10V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7957 16 17V10C16 9.20436 15.6839 8.4413 15.1213 7.87869C14.5587 7.31608 13.7956 7.00001 13 7.00001ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8947 13.2652 18 13 18H3C2.73478 18 2.48043 17.8947 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73479 2.10536 9.48044 2.29289 9.2929C2.48043 9.10537 2.73478 9.00001 3 9.00001H13C13.2652 9.00001 13.5196 9.10537 13.7071 9.2929C13.8946 9.48044 14 9.73479 14 10V17Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
          <div
            className="ser"
            onClick={() => navigate("/portal/index")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="small" color="b800">
                Back to Dashboard
              </Typography>
            </div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
                stroke="#0065FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 12H21L18 9"
                stroke="#0065FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 15L21 12"
                stroke="#0065FF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  );
}
