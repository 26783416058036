import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {  Link, Modal } from "@carbon/react";
import { useNavigate } from "react-router-dom";
export default function AssesmentHeader() {
  const navigate = useNavigate();
  const [modal, setmodal] = useState(false);
  return (
    <>
      <Modal
        open={modal}
        className='userAddModal'
        modalHeading="Are you sure you want to return to the dashboard? "
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onRequestClose={() => setmodal(false)}
        onRequestSubmit={() =>  navigate("/portal/index")}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
        Keep in mind that leaving now will erase the progress you've made in this section.
        </p>
      </Modal>
      <div className="assesmentHeader">
        <Container maxWidth="xl">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src="/lgborder.svg" alt="" srcset="" />
            <div
              className="icn usa"
              onClick={() => setmodal(true)}
              style={{ cursor: "pointer", paddingTop: "8px" }}
            >
              <div className="assesmentUser">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19"
                      stroke="#0065FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12L11 18"
                      stroke="#0065FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5 12L11 6"
                      stroke="#0065FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div style={{ paddingTop: "2px", paddingBottom: "4px" }}>
                  <Typography variant="small" color="neutral.b800">
                    Back to Dashboard
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
