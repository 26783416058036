import React, { useContext, useState } from "react";
import { Link } from "@carbon/react";
import { UserContext } from "../../../userContext";
import apiL from "../../../api/apiList";
import { SendEmailFromServerEnterprise } from "../../planFunctions";
export default function SinglePLan({
  Planname,
  description,
  planID,
  invoice,
  price,
  currency,
  setmodalE,
  setmodalP,
  modalE,
  email,
  setemail,
  desc,
  setdesc,
  planUpdate,
  setnplan,
  isCurrent,
  actPlan,
  setpdet,
  fplan
}) {
  const { user, setUser } = useContext(UserContext);

  let pid = localStorage.getItem("pid");
  function formatIndianNumberingSystem(number) {
    let numStr = number.toString();
    let result = "";
    let count = 0;

    // Reverse the number string for easier processing
    numStr = numStr.split("").reverse().join("");

    for (let i = 0; i < numStr.length; i++) {
      result += numStr[i];
      // Manage the comma after the first three digits
      if (i == 2 && numStr.length > 3) {
        result += ",";
      } else if (i > 2 && (i - 2) % 2 === 0) {
        // Manage commas for every two digits thereafter
        result += ",";
      }
    }

    // Reverse to correct the order and trim any leading comma
    result = result.split("").reverse().join("");
    if (result.startsWith(",")) {
      result = result.substring(1);
    }
    return result;
  }
  function formatNumberWithCommas(number) {
    return number.toLocaleString();
  }
  const shouldShowButton = () => {
    if (actPlan === Planname || Planname === "Custom") {
      return false;
    }
  
    switch (actPlan) {
      case "Basic":
        return Planname === "Premium" || Planname === "Standard";
      case "Standard":
        return Planname === "Premium";
      case "Premium":
        return false;
      default:
        return true;
    }
  };
  return (
    <>
      <div className="single-plan single-plans">
        <div style={{ height: "230px" }}>
          <h3 className="plan-heading">{Planname}</h3>
          {/* <h3 className="plan-heading"></h3> */}
          <h3 className="plan-heading">
            {Planname === "Custom" ? (
              <span style={{ color: "#0065FF", fontSize: "36px" }}>
                Custom Pricing
              </span>
            ) : (
              <span style={{ color: "#0065FF", fontSize: "36px" }}>
                {currency == 55 ? "" : "$"}
                {currency == 55
                  ? formatIndianNumberingSystem(price)
                  : formatNumberWithCommas(price)}{" "}
                <span style={{ color: "#0065FF", fontSize: "30px" }}>
                  {" "}
                  {currency == 55 ? "BDT" : "USD"}
                </span>
              </span>
            )}
          </h3>
          <p>{description}</p>
          <br />
          {/* <p>Keep chugging your coffee.</p> */}
          <div className="single-plan-price">
            <span style={{ color: "#0065FF", fontSize: "28px" }}>
              {" "}
              {isCurrent == true ? "Your Plan" : ""}
            </span>
          </div>
        </div>
        {Planname === "Custom" ? (
          <Link
            className="single-plan-btn"
            onClick={() => {
              setmodalE(true);
            }}
          >
            Contact Us
          </Link>
        ) : (
          ""
        )}
        {shouldShowButton() && (
      <Link
        className="single-plan-btn"
        onClick={() => {
          setnplan(planID);
          setmodalP(true);
          setpdet(fplan)
        }}
      >
        Get Started
      </Link>
    )}
      </div>
    </>
  );
}
