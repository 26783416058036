import React from "react";
import animationData from "./panLoad.json";
import Lottie from "react-lottie";
export default function LoadingScreen() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="loaderscreen">
    
      <Lottie options={defaultOptions} height={380} width={350} />
    </div>
  );
}
