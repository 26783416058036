import React, { useEffect, useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import {
  userSchema,
  businessSchema,
} from "../../Components/onBoard/profileSchema";
import { UserContext } from "../../userContext";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import apiL from "../../api/apiList";
export default function BusinessProfile({ setcount, profileData, setProfile }) {
  const [IndustryList, setIndustryList] = useState([]);
  const [countryList, setcountryList] = useState([]);
  const [scountry, setscountry] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [currencyList, setcurrencyList] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(businessSchema),
  });
  const onSubmit = async (data) => {
    let x = {
      ...profileData,
      ...data,
      // revenue_currency: "1",
      physical_location: parseInt(data.physical_location),
      employee_number: parseInt(data.employee_number),
      business_age: parseInt(data.business_age),
    };
    setProfile(x);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ ...x });
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.createProfile, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result[0].profile_id) {
          localStorage.setItem("id", dsa.result[0].profile_id);
          setUser({ ...user, id: dsa.result[0].profile_id });
          setcount(2);
        }
      })
      .catch((error) => console.log("error", error));
  };
  async function getIndustry() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_BASE_URL}get_industry_list/`, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setIndustryList(dsa.result);
      })
      .catch((error) => console.log("error", error));
    fetch(apiL.getCountry, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setcountryList(dsa.result);
      })
      .catch((error) => console.log("error", error));
    fetch(apiL.getCurrency, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setcurrencyList(dsa.result.reverse());
      })
      .catch((error) => console.log("error", error));
  }
  const watchShowAge = watch("business_state", true);

  const [stateList, setstateList] = useState([]);

  function getState(e) {
    let newArray = countryList.filter(function (el) {
      return el.name == e;
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}get_state_list/` + newArray[0].id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setstateList(result?.result))
      .catch((error) => console.log("error", error));
  }
  function checkBusiness() {
    if (watchShowAge == "") {
      return "";
    } else if (watchShowAge == "startup") {
      return "Providing innovative solutions, facing uncertainty, limited resources, rapid growth, and needs early-stage guidance.";
    } else if (watchShowAge == "turnaround") {
      return "A vital phase in business, addressing distress, declining performance, financial woes, and implementing recovery strategies.";
    } else if (watchShowAge == "accelerated") {
      return "Swift growth stems from innovation, market capture, or investment; managing challenges, scaling efficiently, retaining competitiveness.";
    } else if (watchShowAge == "realignment") {
      return "Strategic adaptation to changing markets, internal challenges; optimize resources, align strategies for competitiveness, sustainability.";
    } else if (watchShowAge == "sustaining-success") {
      return "Stable market position, strong brand, revenue; maintain, innovate, manage risks for ongoing growth.";
    } else {
      return "";
    }
  }
  useEffect(() => {
    getIndustry();
  }, []);
  return (
    <Container maxWidth="xl">
      <form className="responsive-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="onbUserProfileBtn">
              <div>
                <Typography variant="h4B" color="neutral.b800">
                  Nice to meet you, {profileData.first_name}! Let's get to know
                  about your business
                </Typography>
              </div>
              {/* <div>
                <Typography variant="small" color="neutral.b200">
                  We mention the privacy policy and terms we adhere to here so
                  the business owner feels safe to share their information with
                  us
                </Typography>
              </div> */}
              <div className="laptopphoto">
                <img src="/userProfile.webp" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className="onBScroll">
              <div className="onbInput">
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Business Name<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Business Name"
                    invalid={errors.business_name ? true : false}
                    invalidText={errors.business_name?.message}
                    {...register("business_name", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Business Type<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Business Consulting, Manufacturing, Tech"
                    invalid={errors.business_type ? true : false}
                    invalidText={errors.business_type?.message}
                    {...register("business_type", { required: true })}
                  />
                </div>
                <div className="onin">
                  <Select
                    id="select-1"
                    defaultValue=""
                    labelText={
                      <span>
                        Business Stage<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    helperText={checkBusiness()}
                    invalid={errors.business_state ? true : false}
                    invalidText={errors.business_state?.message}
                    {...register("business_state", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value=""
                      text="Business Stage"
                      selected
                    />
                    <SelectItem value="startup" text="Start-Up" />
                    <SelectItem value="turnaround" text="Turn Around" />
                    <SelectItem value="accelerated" text="Accelerated Growth" />
                    <SelectItem value="realignment" text="Realignment" />
                    <SelectItem
                      value="sustaining-success"
                      text="Sustaining Success"
                    />
                  </Select>
                </div>
                <div className="onin">
                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText={
                      <span>
                        Industry<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    invalid={errors.industry_id ? true : false}
                    invalidText={errors.industry_id?.message}
                    {...register("industry_id", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value=""
                      selected
                      text="Industry"
                    />
                    {IndustryList?.map((e) => {
                      return <SelectItem value={e.name} text={e.name} />;
                    })}
                  </Select>
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Website"
                    placeholder="Website"
                    invalid={errors.website ? true : false}
                    invalidText={errors.website?.message}
                    {...register("website", { required: false })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Address Line 1<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Address Line 1"
                    invalid={errors.address_line ? true : false}
                    invalidText={errors.address_line?.message}
                    {...register("address_line", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText="Address Line 2"
                    placeholder="Address Line 2"
                    invalid={errors.address_line_two ? true : false}
                    invalidText={errors.address_line_two?.message}
                    {...register("address_line_two", { required: false })}
                  />
                </div>
                <div className="oninn">
                  <Select
                    id="sele1"
                    defaultValue=""
                    labelText={
                      <span>
                        Country<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    invalid={errors.country_id ? true : false}
                    invalidText={errors.country_id?.message}
                    {...register("country_id", { required: true })}
                    onChange={(e) => {
                      setValue("country_id", e.target.value);
                      getState(e.target.value);
                    }}
                  >
                    <SelectItem value="" text="Country" />
                    {countryList?.map((e) => {
                      return <SelectItem value={e.name} text={e.name} />;
                    })}
                  </Select>
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        Zip Code<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Zip Code"
                    invalid={errors.zip ? true : false}
                    invalidText={errors.zip?.message}
                    {...register("zip", { required: true })}
                  />
                </div>
                <div className="oninn">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="text"
                    labelText={
                      <span>
                        City<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="City"
                    invalid={errors.city ? true : false}
                    invalidText={errors.city?.message}
                    {...register("city", { required: true })}
                  />
                  <Select
                    id="sele1"
                    defaultValue=""
                    labelText={
                      <span>
                        State<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    invalid={errors.state_id ? true : false}
                    invalidText={errors.state_id?.message}
                    onChange={(e) => getState(e.target.value)}
                    {...register("state_id", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value=""
                      text="State"
                      selected
                    />
                    {stateList?.map((e) => {
                      return <SelectItem value={e.name} text={e.name} />;
                    })}
                  </Select>
                </div>

                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="number"
                    placeholder="Years In Business"
                    labelText={
                      <span>
                        Years In Business
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    invalid={errors.business_age ? true : false}
                    invalidText={errors.business_age?.message}
                    min="0"
                    {...register("business_age", { required: true })}
                  />
                </div>

                <div className="onin">
                  <Select
                    id="select-1"
                    defaultValue=""
                    labelText={
                      <span>
                        Business Legal Structure
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    invalid={errors.legal_structure ? true : false}
                    invalidText={errors.legal_structure?.message}
                    {...register("legal_structure", { required: true })}
                  >
                    <SelectItem
                      disabled
                      hidden
                      value=""
                      text="Business Legal Structure"
                      selected
                    />
                    <SelectItem value="Corporation" text="Corporation" />
                    <SelectItem value="Partnership" text="Partnership" />
                    <SelectItem
                      value="Limited Liability Company"
                      text="Limited Liability Company"
                    />
                    <SelectItem
                      value="Sole Proprietorship"
                      text="Sole Proprietorship"
                    />
                    <SelectItem value="Joint Venture" text="Joint Venture" />
                    <SelectItem value="Other" text="Other" />
                  </Select>
                </div>
                <div className="oninn">
                  <div className="on25">
                    <Select
                      id="select-1"
                      defaultValue=""
                      labelText={
                        <span>
                          Currency<span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      invalid={errors.revenue_currency ? true : false}
                      invalidText={errors.revenue_currency?.message}
                      {...register("revenue_currency", { required: true })}
                    >
                      {/* <SelectItem
                        disabled
                        hidden
                        value=""
                        text="USD"
                        selected
                      /> */}
                      {currencyList?.map((e) => {
												return <SelectItem value={e.id} text={e.name} />;
											})}
                    </Select>
                  </div>
                  <div className="on75">
                    <Select
                      id="select-1"
                      defaultValue=""
                      labelText={
                        <span>
                          Revenue Range<span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      invalid={errors.revenue_range ? true : false}
                      invalidText={errors.revenue_range?.message}
                      {...register("revenue_range", { required: true })}
                    >
                      <SelectItem
                        disabled
                        hidden
                        value=""
                        text="Select Range"
                        selected
                      />
                      <SelectItem
                        value="Less than 500,000"
                        text="Less than 500,000"
                      />
                      <SelectItem
                        value="500,001-1,000,000"
                        text="500,001-1,000,000"
                      />
                      <SelectItem
                        value=" 1,000,001-3,000,000"
                        text=" 1,000,001-3,000,000"
                      />
                      <SelectItem
                        value=" 3,000,001 -5,000,000 "
                        text=" 3,000,001 -5,000,000 "
                      />
                      <SelectItem
                        value=" 5,000,001 -10,000,000"
                        text=" 5,000,001 -10,000,000"
                      />
                      <SelectItem
                        value=" 10,000,001 - 20,000,000 "
                        text=" 10,000,001 - 20,000,000 "
                      />
                      <SelectItem
                        value="More than 20,000,000 "
                        text=" More than 20,000,000"
                      />
                    </Select>
                  </div>
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="number"
                    labelText={
                      <span>
                        Number of Employees
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="Number of Employees"
                    invalid={errors.employee_number ? true : false}
                    invalidText={errors.employee_number?.message}
                    min="0"
                    {...register("employee_number", { required: true })}
                  />
                </div>
                <div className="onin">
                  <TextInput
                    className="txtF"
                    id="text-input-1"
                    type="number"
                    labelText={
                      <span>
                        Physical Location
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    placeholder="e.g. 2"
                    invalid={errors.physical_location ? true : false}
                    invalidText={errors.physical_location?.message}
                    min="0"
                    {...register("physical_location", { required: true })}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="onbBtn">
                <Button
                  kind="primary"
                  size="lg"
                  className="btnss"
                  onClick={() => setcount(0)}
                >
                  Previous
                </Button>
                <Button kind="primary" size="lg" type="submit">
                  Next
                </Button>
              </div>
              <div style={{ paddingTop: "28px", paddingLeft: "8px" }}>
                <p
                  style={{
                    color: "#2461FF",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("https://forms.gle/HDzxxsNVhr7KrJZN8")
                  }
                >
                  Having a problem? Report an issue
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
