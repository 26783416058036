import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { InlineWidget } from "react-calendly";
import apiL from "../../api/apiList";
import { UserContext } from "../../userContext";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Portal/layout";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  Select,
  SelectItem,
  TextInput,
} from "@carbon/react";
import LoadingScreen from "../../loading";
import { checkAccess } from "../../Components/planFunctions";
export default function Consultation({ checkPlan, checkValidity }) {
  const navigate = useNavigate();
  const [loadingBar, setloadingBar] = useState(true);
  const { user, setUser } = useContext(UserContext);
  const [data, setdata] = useState({});
  const [Plan, setPlan] = useState(null);
  const [logOutSes, setlogOutSes] = useState(false);
  async function getConsultant() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getConsultant + user.id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (
          dsa.result &&
          dsa.result.message == "This user has no consultant."
        ) {
          setdata({
            profile_code: "JD-1000-BD-0005",
            profile_id: 0,
            remaining_number: 0,
            total_number: 0,
          });
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
        setdata(dsa.result.consultants[0]);
      })
      .catch((error) => console.log("error", error));
  }
  async function init() {
    let x = await checkPlan();

    setPlan(x);
  }
  let onb = localStorage.getItem("onboard");
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false);
        navigate("/signin");
        const keysToRemove = [
          "vd",
          "id",
          "pid",
          "uid",
          "image",
          "onboard",
          "name",
          "invited",
        ];

        // Iterate over each key and remove the item from localStorage
        keysToRemove.forEach((key) => {
          localStorage.removeItem(key);
        });
      })
      .catch((error) => console.log("error", error));
  };
  if (onb === "false") {
    Logout();
    navigate("/signin");
  }
  useEffect(() => {
    getConsultant();
    init();
    setTimeout(() => {
      setloadingBar(false);
    }, 2000);
  }, []);

  return (
    <>
      <Layout
        logOutSes={logOutSes}
        setlogOutSes={setlogOutSes}
        checkPlan={checkPlan}
        checkValidity={checkValidity}
      />
      {loadingBar ? (
        <LoadingScreen />
      ) : (
        <>
          <Modal
            open={logOutSes}
            className="userAddModal"
            modalHeading="You will be logged out"
            primaryButtonText="Logout"
            secondaryButtonText="Cancel"
            onRequestClose={() => setlogOutSes(false)}
            onRequestSubmit={() => Logout()}
            size="sm"
          >
            <p style={{ marginBottom: "1rem" }}>
              Are you sure you want to log out of your Panorama account?
            </p>
          </Modal>
          <div className="innerPortal bg-grey-plan">
            <div className="cardFullDashboard">
              <Grid container spacing={0}>
                <Grid sm={12} md={12} lg={6} xl={6}>
                  <div className="txtCardFullDash">
                    <div>
                      <Typography variant="h4B" color="neutral.b800">
                        Consultation
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="small" color="neutral.b800">
                        Schedule a session with our team of experts.
                      </Typography>
                    </div>
                    <Button
                      kind="primary"
                      size="lg"
                      onClick={() => navigate("/portal/plans")}
                    >
                      Upgrade Plan
                    </Button>
                  </div>
                </Grid>
                <Grid sm={12} md={12} lg={6} xl={6}>
                  <div className="plan-txt2">
                    <div>
                      <Typography variant="small" color="neutral.b80">
                        Your Package
                      </Typography>
                      <br />
                      <Typography variant="largeB" color="primary.b300">
                        {Plan == null ? "No active plan" : Plan?.plan_name}
                      </Typography>
                    </div>
                    <div className="plancta">
                      <div className="planctaCard">
                        <Typography variant="small" color="neutral.b80">
                          Total Sessions
                        </Typography>
                        <br />
                        <Typography variant="largeB" color="neutral.b400">
                          {data.total_number}
                        </Typography>
                      </div>
                      <div className="planctaCard">
                        <Typography variant="small" color="neutral.b80">
                          Remaining Sessions
                        </Typography>
                        <br />
                        <Typography variant="largeB" color="neutral.b400">
                          {data.remaining_number}
                        </Typography>
                      </div>
                      {/* <div className="planctaCard">
                  <Typography variant="small" color="neutral.b80">
                    Assesment Access
                  </Typography>
                  <br />
                  <Typography variant="largeB" color="neutral.b400">
                    5 Users
                  </Typography>
                </div> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            
            <div>
              {data.remaining_number == 0 ? (
                <div className="consultError">
                  <Typography variant="largeB" color="neutral.b400">
                    Sorry, you don't have any sessions left
                  </Typography>
                </div>
              ) : (
                <InlineWidget
                  url="https://calendly.com/panoramaltd/consultation"
                  styles={{
                    height: "900px",
                    width: "100%",
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
