import Expired from "./Pages/expired";
import { createContext, useEffect, useState, useMemo } from "react";
import {
  checkAccess,
  checkPlan,
  checkValidity,
} from "./Components/planFunctions";
import EmailFailed from "./Pages/Email_verification_failed";
export default function ConditionalRoutes({ children }) {
  const vd = localStorage.getItem("vd")
  const [validity, setvalidity] = useState("");
  function checkvc() {
    checkValidity()
      .then((value) => {
    
        setvalidity(value);
      })
      .catch((error) => {
        console.log("Error:", error);
        // Handle errors here
      });
  }

  useEffect(() => {
    checkvc();
  }, []);
  // Check for access conditions here
  // if (validity === "Expired") {
  //   return <Expired />; // Assuming Expired is the component for denied access
  // }
  if (vd === "false") {
    return <EmailFailed />; // Assuming Expired is the component for denied access
  }

  return <>{children}</>; // Render children when conditions are met
}
