import React from "react";
import ResizableBox from "./ResizableBox";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function HBar({ data, name }) {
  function convertDateFormat(dateStr) {
    // Split the date string into components
    const [year, month, day] = dateStr.split('-');
    
    // Extract the last two digits of the year
    const shortYear = year.slice(-2);
    
    // Return the date in the desired format
    return `${day}/${month}/${shortYear}`;
}
  const labels = data.map((entry) => entry.name+" -"+entry.date);
  const options = {
    indexAxis: "y",
    width:1800,
    scales: {
      x: {
        max: 100, // Set your desired max value
        width:"63%",
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: name,
      },
    },
  };
  let datas = {
    labels,
    datasets: [
      {
        label: "Yes",
        data: data.map((entry) => entry.yes),
        borderColor: "rgb(0, 128, 0)",
        backgroundColor: "rgb(192, 255, 192)",
      },
      {
        label: "No",
        data: data.map((entry) => entry.no),
        borderColor: "rgb(255, 0, 0)",
        backgroundColor: "rgb(255, 192, 192)",
      },
      {
        label: "Unsure",
        data: data.map((entry) => entry.unsure),
        borderColor: "rgb(255, 165, 0) ",
        backgroundColor: "rgb(255, 255, 192)",
      },
    ],
  };
  return (
    <>
      <br></br>
      <br></br>
      <div className="bgf">
        <div className="barCOmpare">
          <div className="chartContainer">
            <Bar options={options} data={datas} />
          </div>
        </div>
      </div>
    </>
  );
}
