import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  Button,
  Modal,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import { useNavigate } from "react-router-dom";
import apiL from "../../../api/apiList";
import Grid from "@mui/material/Grid";
import Layout from "../layout";
import LoadingScreen from "../../../loading";
export default function ReportsTab() {
  const [loadingBar, setloadingBar] = useState(true)
  const [rows, setrows] = useState([]);
  const navigate = useNavigate();
  const [logOutSes, setlogOutSes] = useState(false);
  const headers = ["Name", "Company", "Date", "Action"];
  async function getAssesment() {
    let id = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.asseslist + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result) {
          let data = dsa.result.assessments;
          setrows(data);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  let onb = localStorage.getItem("onboard");
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false);
        navigate("/signin");
        const keysToRemove = ['vd', 'id', 'pid', 'uid', 'image', 'onboard', 'name', 'invited'];

// Iterate over each key and remove the item from localStorage
keysToRemove.forEach(key => {
  localStorage.removeItem(key);
});
      })
      .catch((error) => console.log("error", error));
  };
  if (onb === "false") {
    Logout();
    navigate("/signin");
  }
  useEffect(() => {
    getAssesment();
    setTimeout(()=>{
 
      setloadingBar(false)
    }, 2000)
  }, []);
  return (
    <div>
      <Layout logOutSes={logOutSes} setlogOutSes={setlogOutSes} />
      {loadingBar? <LoadingScreen/>: <>
      <Modal
        open={logOutSes}
        className="userAddModal"
        modalHeading="You will be logged out"
        primaryButtonText="Logout"
        secondaryButtonText="Cancel"
        onRequestClose={() => setlogOutSes(false)}
        onRequestSubmit={() => Logout()}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Are you sure you want to log out of your Panorama account?
        </p>
      </Modal>
      <div className="innerPortal bg-grey-plan">
        <div className="assTab">
          <Typography variant="h4B" color="neutral.b800">
            Assessment Reports
          </Typography>{" "}
          <div style={{ paddingTop: "25px" }}>
            <Typography variant="small" color="neutral.b800">
              Reports may take around 5-10 minutes to generate. If you do not
              receive a confirmation email within 1 hour of completion, please contact us at <a href="mailto:system@panoramamas.com">system@panoramamas.com</a> for assistance.
            </Typography>
          </div>
        </div>{" "}
        <div className="asstabtable" style={{paddingBottom:'100px'}}>
          {" "}
          <Table size="lg" useZebraStyles={false}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader id={header.key} key={header}>
                    {header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length == 0 ? (
                <div style={{ paddingTop: "25px", paddingLeft: "12px" }}>
                  <span style={{ fontSize: "20px" }}>No Reports Available</span>
                </div>
              ) : (
                rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell key={row.name}>{row.name}</TableCell>
                    <TableCell key={row.company}>{row.company}</TableCell>
                    <TableCell key={row.create_date}>
                      {row.create_date}
                    </TableCell>
                    <TableCell>
                      {row.state == "done" ? (
                        <Button
                          kind="primary"
                          size="sm"
                          className="btnss"
                          onClick={() =>
                            navigate(
                              "/portal/report/overview/?id=" +
                                row.id +
                                "&code=" +
                                row.profile_code
                            )
                          }
                        >
                          View
                        </Button>
                      ) : (
                        <Button
                          kind="primary"
                          size="sm"
                          className="btnss"
                          disabled
                        >
                          View
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      </>}
    </div>

  );
}
