import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import ConsultCardIcon from "../../reUse/ConsultCardIcon";
import SafetyLargeIcon from "../../reUse/SafetyLargeIcon";
import { Button } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "./report.json";
export default function Consult({ data, consultData }) {
  const navigate = useNavigate();
  const pid = localStorage.getItem("pid");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const invited = localStorage.getItem("invited");
  return (
    <div className="cardQuarterDashboard">
      <div className="consultCard" style={{ opacity: "1" }}>
        <div className="headerPlan">
          <ConsultCardIcon />
          <Typography variant="small" color="neutral.b800">
            Consultation
          </Typography>
        </div>
        {data === 0 ? (
          <div className="contentCard">
            <SafetyLargeIcon />
            <div>
              <Typography variant="small" color="neutral.b200">
              Please select a plan to take the assessment
              </Typography>
            </div>
          </div>
        ) : (
          <div className="contentCard2">
            <p>
              Schedule a one on one consultation with our experts for guidance.
            </p>
            <Lottie options={defaultOptions} height={250} width={250} />
            <div className="schedule">
              <div className="schedule-top">
                <div className="single-schedule">
                  <p>Consultations Left</p>
                  <h5>
                    {consultData.remaining_number}/{consultData.total_number}
                  </h5>
                </div>
                <div className="single-schedule">
                  <p>Duration</p>
                  <h5>60 Mins</h5>
                </div>
              </div>
              {/* <div className="upcoming-consultation">
                <img src="/chat.svg" />
                No Upcoming Consultations
                </div> */}
            </div>
          </div>
        )}
        {(data === 0 ) & (invited === "false") ? (
          <Button
            onClick={() => navigate("/portal/plans")}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
          >
            View Available Plans
          </Button>
        ) : consultData.remaining_number == 0 ? (
          ""
        ) : (
          <Button
            onClick={() => navigate("/portal/consultation")}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
          >
            Take Consultation
          </Button>
        )}
      </div>
    </div>
  );
}
