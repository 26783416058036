import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "chart.js";
import "chartjs-adapter-date-fns";
Chart.register(...registerables);
export default function LinearChart({ da, das, i }) {

  const colors = [
    "#845EC2",
    "#4B4453",
    "#B0A8B9",
    "#FF8066",
    "#005f73",
    "#009EFA",
    "#4FFBDF",
    "#009EFA",
    "#005B44",
  ];

  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          tooltipFormat: "MMMM yyyy",
        },
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        max: 120,
        beginAtZero: true,
        title: {
          display: true,
          text: "Value",
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: das,
        font: {
          size: 28,
        },
      },
    },
  };
  function modifyData(dat) {
    let x = [];
    dat.forEach((element, a) => {
      x.push({
        label: element?.name,
        data: element?.Blocks[i]?.data,
        borderColor: colors[a],
        backgroundColor: colors[a],
      });
    });
    return x;
  }
  let labels = da[0]?.datasets;
  let datax = {
    labels,
    datasets: modifyData(da),
  };

  return (
    <>
      <br></br>
      <br></br>
      <div className="bgf">
        <div className="barCOmpare">
          <div className="chartContainer">
            <Line data={datax} options={options} />
          </div>
        </div>
      </div>
    </>
  );
}
