import React from "react";
import { Button } from "@carbon/react";
import Typography from "@mui/material/Typography";
const rdata = {
	"Vision":
		"Your vision serves as the compass, guiding your business toward its long-term destination.",
	"Mission":
		" Your mission articulates the purpose and key objectives that drive your business’s existence.",
	"Values":
		"Your communicated values define the ethical principles shaping your company's culture and decision-making.",
	"People":
		"Prioritizing people fosters team cohesion in open communication and motivated workforce, aligning them with the organization's goals.",
	"Customer":
		"Customer commitment entails understanding and fulfilling their needs, nurturing satisfaction and loyalty.",
	"Marketing":
		"Strategic marketing effectively communicates and promotes your business's value proposition to the target audience.",
	"Operations/Productions":
		"Effective operations and production management ensures the delivery of high-quality outputs, meeting customer demand efficiently.",
	"Reporting and Feedback":
		" Actively collecting and analyzing performance data facilitates continuous improvement within the organization.",
	"IT and Financial Control":
		"Attentiveness to IT and financial control safeguards the organization's digital infrastructure and maintains financial health.",
	"Strategy":
		"Strategic planning charts are comprehensive path to achieve business objectives and maintain competitiveness in the market.",
};
export default function AssesmentCard({
	selected,
	setselected,
	data,
	handleNext,
	error,
}) {
	return (
		<div className="assesmentCard">
			<div className="assesmentCardimg">
				<img src="/assesmentCard.png" />
				<div className="asscardText">
					{/* <div>
						<Typography variant="xsmall" color="neutral.b50">
							People
						</Typography>
					</div> */}
					<div>
						<Typography variant="smallB" color="neutral.b0">
							{data[parseInt(selected) - 1]?.name || "Loading"}
						</Typography>
					</div>
					<div>
						<Typography
							variant="xsmall"
							color="neutral.b0"
							style={{ marginTop: "15px", display: "block" }}
						>
							{rdata[data[parseInt(selected) - 1]?.name] || "Loading"}
						</Typography>
					</div>
					{/* <div style={{ paddingTop: "12px" }}>
						<Button
							kind="primary"
							size="sm"
							className="btnca"
							// onClick={() => handleNext()}
						>
							Learn More
						</Button>
					</div> */}
				</div>
			</div>

			<div className="segment">
				{data.map((e) => {
					return (
						<div
							className={
								selected == e.segment_id
									? "segActiveSel"
									: parseInt(selected) > parseInt(e.segment_id)
									? "segActive"
									: "seg"
							}
						>
							<Typography variant="xsmall" color="neutral.b800">
								{e.name}
							</Typography>
						</div>
					);
				})}
				{/* <div className="segActive">
          <Typography variant="xsmall" color="neutral.b800">
            Vision, Mission, and Values
          </Typography>
        </div>
        <div className="segActiveSel">
          <Typography variant="xsmall" color="neutral.b800">
            Vision, Mission, and Values
          </Typography>
        </div> */}

				<div className="buttonSeg">
					{/* <div className="btnspace">
            <button className="customBtnL">Save and Close</button>
          </div> */}
					<div style={{ width: "100%" }}>
						{selected !== "10" ? (
							<button className="customBtnD" onClick={() => handleNext()}>
								Next
							</button>
						) : (
							<>
								{" "}
								{selected === "11" ? (
									""
								) : (
									<button className="customBtnD" onClick={() => handleNext()}>
										Submit
									</button>
								)}
							</>
						)}
					</div>
				</div>
				<div className="warnText">
					{error === "" ? (
						<Typography variant="xsmall" color="neutral.b900">
							*Please complete the current section to save progress and move on to the next section. 
						</Typography>
					) : (
						<Typography
							variant="xsmallB"
							color="error.main"
							style={{ marginTop: "15px", display: "block" }}
						>
							{error}
						</Typography>
					)}
				</div>
			</div>
		</div>
	);
}
