import React, { useState } from "react";
import DashboardIndex from "../../Components/Portal/dashboard";
import PortalHeader from "../../Components/Portal/header";
import Layout from "../../Components/Portal/layout";
import UserInfo from "../../Components/Portal/UserInfo";
import apiL from "../../api/apiList";
import { useNavigate } from "react-router-dom";
import LoaderAnim from "../../Components/Loader";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  Select,
  SelectItem,
  TextInput,
} from "@carbon/react";
import Lottie from "react-lottie";
import animationData from "../../Components/sorry.json";
import Expired from "../expired";
export default function PortalIndex({ checkPlan, checkValidity }) {
  const [count, setcount] = useState(0);
  const navigate = useNavigate();
  const [logOutSes, setlogOutSes] = useState(false);
  let onb = localStorage.getItem("onboard");
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false);
        navigate("/signin");
        const keysToRemove = [
          "vd",
          "id",
          "pid",
          "uid",
          "image",
          "onboard",
          "name",
          "invited",
        ];

        // Iterate over each key and remove the item from localStorage
        keysToRemove.forEach((key) => {
          localStorage.removeItem(key);
        });
      })
      .catch((error) => console.log("error", error));
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (onb === "false") {
    Logout();
    navigate("/signin");
  }
  return (
    <>
      <Layout
        logOutSes={logOutSes}
        setlogOutSes={setlogOutSes}
        checkPlan={checkPlan}
        checkValidity={checkValidity}
      />
      <Modal
        open={logOutSes}
        className="userAddModal"
        modalHeading="You will be logged out"
        primaryButtonText="Logout"
        secondaryButtonText="Cancel"
        onRequestClose={() => setlogOutSes(false)}
        onRequestSubmit={() => Logout()}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Are you sure you want to log out of your Panorama account?
        </p>
      </Modal>

      <div className="ptl">

          <DashboardIndex checkPlan={checkPlan} checkValidity={checkValidity} />

      </div>
    </>
  );
}
