import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
export default function Failed() {
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/portal/index'); // Redirect to the specified URL after 3000 milliseconds (3 seconds)
    }, 10000);

    return () => clearTimeout(timeout); // Cleanup function to clear the timeout when the component unmounts
  }, [navigate]);
  return (
    <div className="payment-success-container">
    <div>
    <img src="/lgborder.svg" alt="" srcset="" style={{width:'300px'}} />
    </div>
    <br/>
    <br/>
    <FontAwesomeIcon icon={faTimesCircle} className="cancelled-icon" />
    <h1>Payment Failed</h1>
    <br/>
    <p>Your Payment has Failed.</p>
    <p>You will be redirected to the plans and billings page within 10 seconds. Please wait.</p>
  </div>
  )
}
