import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import ConsultCardIcon from "../../reUse/ConsultCardIcon";
import SafetyLargeIcon from "../../reUse/SafetyLargeIcon";
import { Button } from "@carbon/react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "./Animation - 1707685579199.json";
export default function ReportCard({ data, consultData }) {
  const navigate = useNavigate();
  const pid = localStorage.getItem("pid");
  const invited = localStorage.getItem("invited");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="cardQuarterDashboard">
      <div className="consultCard" style={{ opacity: "1" }}>
        <div className="headerPlan">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1822_11)">
              <path
                d="M10 6.25H7.5C6.83696 6.25 6.20107 6.51339 5.73223 6.98223C5.26339 7.45107 5 8.08696 5 8.75V23.75C5 24.413 5.26339 25.0489 5.73223 25.5178C6.20107 25.9866 6.83696 26.25 7.5 26.25H14.6212"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.5 15V8.75C22.5 8.08696 22.2366 7.45107 21.7678 6.98223C21.2989 6.51339 20.663 6.25 20 6.25H17.5"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 6.25C10 5.58696 10.2634 4.95107 10.7322 4.48223C11.2011 4.01339 11.837 3.75 12.5 3.75H15C15.663 3.75 16.2989 4.01339 16.7678 4.48223C17.2366 4.95107 17.5 5.58696 17.5 6.25C17.5 6.91304 17.2366 7.54893 16.7678 8.01777C16.2989 8.48661 15.663 8.75 15 8.75H12.5C11.837 8.75 11.2011 8.48661 10.7322 8.01777C10.2634 7.54893 10 6.91304 10 6.25Z"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 13.75H15"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 18.75H13.75"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 21.875C17.5 22.7038 17.8292 23.4987 18.4153 24.0847C19.0013 24.6708 19.7962 25 20.625 25C21.4538 25 22.2487 24.6708 22.8347 24.0847C23.4208 23.4987 23.75 22.7038 23.75 21.875C23.75 21.0462 23.4208 20.2513 22.8347 19.6653C22.2487 19.0792 21.4538 18.75 20.625 18.75C19.7962 18.75 19.0013 19.0792 18.4153 19.6653C17.8292 20.2513 17.5 21.0462 17.5 21.875Z"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.125 24.375L26.25 27.5"
                stroke="#2461FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1822_11">
                <rect width="30" height="30" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <Typography variant="small" color="neutral.b800">
            Reports
          </Typography>
        </div>
        {data === 0 ? (
          <div className="contentCard">
            <SafetyLargeIcon />
            <div>
              <Typography variant="small" color="neutral.b200">
              Please select a plan to take the assessment
              </Typography>
            </div>
          </div>
        ) : (
          <div className="contentCard2">
            <p>
            Click 'View Reports' to see all your available reports.
            </p>
            <Lottie options={defaultOptions} height={320} width={320} />
          </div>
        )}
				{(data === 0 ) & (invited === "false") ? (
          <Button
            onClick={() => navigate("/portal/plans")}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
          >
            View Available Plans
          </Button>
        ):          <Button
            onClick={() => navigate("/portal/assessmentreports")}
            className="btnss"
            style={{ maxWidth: "100%", width: "100%" }}
            kind="primary"
            size="lg"
          >
            View Reports
          </Button> }
      </div>
    </div>
  );
}
