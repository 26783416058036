import * as yup from "yup";

const signUp_schema = yup.object().shape({
    email: yup.string().email().required("Please enter email"),
    password: yup.string().required("Please enter password") .min(8, "Password must be at least 8 characters")
    .max(32)
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[!@#$%^&*(),.?":{}|<>_]/, "Password must contain at least one special character"),
    name: yup.string().required("Please enter name"),
    country_id: yup.string().required("Please enter country"),
    marketing_email: yup.boolean().required()
  });
  const signIn_schema = yup.object().shape({
    email: yup.string().email().required("Please enter email"),
    password:yup.string().required("Please enter password") .min(8, "Password must be at least 8 characters")
    .max(32)
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[!@#$%^&*(),.?":{}|<>_]/, "Password must contain at least one special character"),
  });
  export {signUp_schema,signIn_schema}