import React from "react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  Select,
  SelectItem,
  TextInput,
} from "@carbon/react";
import { useNavigate } from "react-router-dom";
import apiL from "../api/apiList";
export default function EmailFailed() {
  const navigate = useNavigate();
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };

    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();

        navigate("/signin");
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div className="payment-success-container">
      <div>
        <img src="/lgborder.svg" alt="" srcset="" style={{ width: "300px" }} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src="/expired.svg" height={500} />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ marginBottom: "1rem" }}>
        Sorry, we couldn’t verify your email. Please check your inbox for the Email Verification mail.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "22px",
        }}
      >
        <Button
          // style={{ maxWidth: "48%", width: "48%",marginRight:'24px' }}
          kind="primary"
          size="lg"
          onClick={() => Logout()}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
